import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import DropdownOption from "components/Common/DropdownOption";
import { TYPE_SCRIPT_OPTIONS } from "helpers/constans";
import moment from "moment";
import MemberInformation from "../MemberInformation";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { toast } from "react-toastify";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import GeneralMemberInformation from "../GeneralMemberInformation";
interface Props {
  media: any;
  onClose: () => void;
  onRefresh: () => void;
}
const MediaModal = ({ media, onClose, onRefresh }: Props) => {
  const { t } = useTranslation();
  const [mediaDetail, setMediaDetail] = useState<any>();
  const [tabModal, setTabModal] = useState("1");

  const getDetail = async (id: number) => {
    const [err, result] = await catchAsync(MediaApi.getDetailMedia(id));

    if (err) {
        toast("error", err.message);
        return
    };
    setMediaDetail(result?.data);
  };

  useEffect(() => {
    if (media) {
      getDetail(media.id);
    }
  }, [media]);

  return (
    <Modal
      isOpen={!!media}
      centered={true}
      scrollable={false}
      style={{ minWidth: 800 }}
    >
      <ModalHeader>
        <Nav
          className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0 gap-3"
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "1",
              })}`}
              onClick={() => {
                setTabModal("1");
              }}
            >
              {t("media.MediaInformation")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "2",
              })}`}
              onClick={() => {
                setTabModal("2");
              }}
            >
              {t("media.MemberInformation")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "3",
              })}`}
              onClick={() => {
                setTabModal("3");
              }}
            >
              {t("media.GeneralMemberInformation")}
            </NavLink>
          </NavItem>
        </Nav>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <TabContent activeTab={tabModal} className="text-muted">
            <TabPane tabId={"1"} id={"tab-1"}>
              <Formik
                initialValues={{
                  company_name: mediaDetail?.name || "",
                  name: mediaDetail?.admin?.name || "",
                  media_category: mediaDetail?.media_category || "",
                  domain: mediaDetail?.domain || "",
                  phone_number: mediaDetail?.admin?.phone_number || "",
                  script_type: mediaDetail?.srcipt_status?.script_type,
                }}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const [err, result] = await catchAsync(
                      MediaApi.updateCompanies({
                        id: mediaDetail?.id,
                        data: {
                          ...values,
                          phone_number: values?.phone_number + "",
                        },
                      })
                    );

                    if (result?.statusCode === 200) {
                      toast("success", CONFIG_OPTION_TOAST_NORMAL);
                      onRefresh();
                      onClose();
                    }
                    if (err) {
                      console.log(err);
                      toast(
                        Object.values(err)?.[0] || err,
                        CONFIG_OPTION_TOAST_ERROR
                      );
                    }
                  } catch (error: any) {
                    toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="company_name" className="form-label">
                            {t("media.Name")}
                          </Label>
                          <Input
                            name="company_name"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={values.company_name}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="name" className="form-label">
                            {t("media.Manager")}
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            maxLength={50}
                            className="form-control"
                            onChange={handleChange}
                            value={values.name}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="media_category"
                            className="form-label"
                          >
                            {t("media.Classification")}
                          </Label>
                          <Input
                            name="media_category"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={values.media_category}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("media.Email")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            disabled
                            value={mediaDetail?.admin?.email || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="domain" className="form-label">
                            {t("media.DomainURL")}
                          </Label>
                          <Input
                            name="domain"
                            type="text"
                            maxLength={50}
                            className="form-control"
                            onChange={handleChange}
                            value={values.domain}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="phone_number" className="form-label">
                            {t("media.PhoneNumber")}
                          </Label>
                          <Input
                            name="phone_number"
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={values.phone_number}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="media_category"
                            className="form-label"
                          >
                            {t("media.ServiceSubscriptionDate")}
                          </Label>
                          <div className="d-flex flex-column gap-2">
                            {mediaDetail?.services?.map(
                              (item: any, index: number) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div
                                    className={`tag-service bg-${item?.service_name}`}
                                  >
                                    {item?.service_name === "CMS" ? "Prc" : item?.service_name}
                                  </div>

                                  <p className="m-0">
                                    {moment(item?.created_at).format(
                                      "YYYY.MM.DD"
                                    )}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("media.ScriptStatus")}
                          </Label>
                          <DropdownOption
                            name="service_type"
                            dataList={TYPE_SCRIPT_OPTIONS?.map((item: any) => ({
                              value: item?.value,
                              label: t(item?.label),
                            }))}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={TYPE_SCRIPT_OPTIONS.map(
                              (item: any) => ({
                                value: item?.value,
                                label: t(item?.label),
                              })
                            )?.find((i) => i.value === values?.script_type)}
                            onChangeSelect={(e: any) => {
                              if (e) {
                                setFieldValue("script_type", e?.value);
                              }
                            }}
                            labelTop={""}
                          />
                          {mediaDetail?.srcipt_status?.updated_at ? (
                            <div className="d-flex text-primary">
                              <p className="m-0">
                                {moment(
                                  mediaDetail?.srcipt_status?.updated_at
                                ).format("YYYY.MM.DD a hh:mm")}
                              </p>
                              <p className="m-0">
                                _{mediaDetail?.srcipt_status?.updated_by}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="media_category"
                            className="form-label"
                          >
                            {t("media.CMSInformation")}
                          </Label>
                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <div className="inline-flex">
                                <p className="m-0 fw-bold inline-table">
                                  {t("media.Membership")}
                                </p>
                                <p className="m-0 fw-bold inline-table">
                                  {t("media.PaymentDate")}
                                </p>
                                <p className="m-0 fw-bold inline-table">
                                  {t("media.NextPaymentDate")}
                                </p>
                                <p className="m-0 fw-bold inline-table">
                                  {t("media.Point")}
                                </p>
                              </div>

                              <div>
                                <p className="m-0">
                                  {mediaDetail?.cms_information?.membership || "-"}
                                </p>
                                <p className="m-0">
                                  {mediaDetail?.cms_information?.payment_date || "-"}
                                </p>
                                <p className="m-0">
                                  {mediaDetail?.cms_information?.next_payment_date ||
                                    "-"}
                                </p>
                                <p className="m-0">
                                  {mediaDetail?.cms_information?.point || 0}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="hstack gap-2 mt-3 justify-content-center">
                      <button
                        className="btn btn-soft-secondary fs-14 w-25"
                        type="button"
                        color="light"
                        onClick={() => onClose()}
                      >
                        {t("media.Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary fs-14 w-25"
                      >
                        {false ? <Spinner size="sm me-2"></Spinner> : null}
                        {t("media.Registration")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={"2"} id={"tab-2"} style={{ height: 550 }}>
              <MemberInformation
                refresh={() => {
                  onRefresh();
                  setTabModal("1");
                  onClose();
                }}
                id={mediaDetail?.id}
                onClose={() => {
                  setTabModal("1");
                  onClose();
                }}
              />
            </TabPane>
            <TabPane tabId={"3"} id={"tab-3"}>
              <GeneralMemberInformation 
                id={mediaDetail?.id}
                onClose={() => {
                  setTabModal("1");
                  onClose();
                }}
                />
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MediaModal;
